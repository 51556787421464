<template>
  <div>
    <p>《看看云医用户协议》</p>
    <p></p>
    <p>（一）用户注册</p>
    <p></p>
    <p>1.注册成为看看云医会员的约定</p>
    <p>
      1.1
      申请会员时，您需提供真实、准确、即时、完整及有效的注册信息，不得用他人资料在看看云医进行注册或认证。若提供虚假注册信息，可能导致注册失败或账号被暂停、终止等后果。
    </p>
    <p>
      1.2
      应维护并及时更新注册信息，以保持其真实、准确、即时、完整及有效。信息发生变更后，应在当天进行更新，否则视为违约。若提供的信息不真实、不准确、不完整，看看云医有权暂停或终止会员服务。
    </p>
    <p>1.3 用户权利</p>
    <p>
      1.3.1
      您拥有在看看云医上使用自行注册的用户名和密码之权利，但不得以任何形式擅自转让或授权他人使用自己的看看云医帐号。您如发现其他用户有违法或违反本服务协议的行为，可以向看看云医进行反映和要求处理。
    </p>
    <p>
      1.3.2 无论您通过何种 APP 下载平台下载本平台应用（包括但不限于谷歌 Google
      Play、苹果 App
      Store、微信小程序），可就其合法权利向看看云医予以主张。下载平台就本平台应用的任何事务无需承担任何责任。同时，您应确保从合法渠道下载本平台应用，如因从非官方渠道下载导致的任何损失，看看云医不承担任何责任。
    </p>
    <p>1.4 用户义务</p>
    <p>
      1.4.1
      您单独承担发布内容的责任。您对服务的使用是根据所有适用于看看云医的国家法律、地方法律和国际法律标准的。您必须遵循以下规定：
    </p>
    <p>
      1.4.2
      使用网络服务不作非法用途；在账号名称、头像和简介等注册信息中不得出现违法和不良信息。
    </p>
    <p>
      1.4.3
      不攻击看看云医的数据、网络或服务，不盗用他人在看看云医上的用户名或密码。
    </p>
    <p>
      1.4.4
      您应当保证在接受看看云医服务的过程中遵守诚实信用的原则，若有网上交易发生的，不在交易过程中采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为；您不冒用其他用户的名义或其他企业等主体的名义发布商业信息，进行商业活动；禁止任何商业目的或恶意传播信息。由此引发的责任由传播者承担。
    </p>
    <p>
      1.4.5
      发生下列情形的，看看云医有权采取限制账号权限、列入黑名单、注销账号等措施：
    </p>
    <p>
      1.4.5.1
      您使用非看看云医提供的工具，例如插件、脚本、外挂等工具进行非正常操作（自动点击、高频率点击等），以及异常手动行为进行非正常操作。若您进行此类操作，可能面临账号被限制等后果，造成严重后果的将承担民事赔偿责任，构成犯罪的依法移交司法部门追究刑事责任。
    </p>
    <p>
      1.4.5.2
      您利用看看云医平台提供的各项服务，以倒卖、赠予、交换挂号凭证等方式，影响正常挂号秩序或者为自己牟取不正当利益，具体情形包括但不限于囤积号源；倒卖挂号凭证等。
    </p>
    <p>
      1.4.5.3
      您不得滥用言论自由权利，平台允许建立主题或者在相应主题后跟帖，且回复内容须跟主题相关且贴切，不得发布与主题无关内容，影响平台正常秩序。
    </p>
    <p>
      1.4.5.4
      您不得使用非正常实名认证的手机号注册看看云医帐号（如物联网卡.、虚拟副卡号、虚拟号、临时号码……）。
    </p>
    <p>
      1.4.5.5
      您对自己实名认证注册帐号具有妥善保管和正确使用的义务。如果以您自己操作不当或者管理不到位，上传相应资料数据错误导致的风险由您本人承担。
    </p>
    <p>
      1.4.5.6
      您若在看看云医上散布和传播反动、色情或其他违反国家法律法规的信息，或者利用看看云医健康服务平台从事其他法律法规规定的禁止性行为，看看云医的系统记录有可能作为您违反法律的证据。同时，看看云医有权向相关部门举报您的违法行为。
    </p>
    <p>（二）看看云医的权利与义务</p>
    <p></p>
    <p>1.看看云医的权利</p>
    <p>
      2.1.1
      “看看云医”在必要时有权修改服务条款，届时将在看看云医界面提示修改内容，如果您不同意所作的修改，可以主动取消已经获得的服务。如果您继续享有看看云医的服务，则被视为接受服务条款变动。同时，看看云医应在修改条款生效前提前
      7 天通知用户，以便用户有足够时间做出决策。
    </p>
    <p>
      2.1.2
      看看云医有权在一定的情形下以网络发布形式公布您在使用看看云医提供的服务过程中的违法行为。公布前，看看云医应进行内部审核，确保公布内容的准确性和合法性。
    </p>
    <p>
      2.1.3
      看看云医有权在不通知您的前提下对您在看看云医发布的下列各类信息进行删除或采取其它限制性措施：
    </p>
    <p>（1）未遵守中华人民共和国法律的信息；</p>
    <p>（2）违反地方法律、法规的信息；</p>
    <p>（3）以炒作信用为目的的信息；</p>
    <p>（4）存在欺诈等恶意或虚假内容的信息；</p>
    <p>
      （5）违反社会公共利益及公序良俗，可能严重损害看看云医和其它用户合法利益的信息。在采取删除或限制措施时，看看云医应记录相关操作并保留一定期限，以备用户查询和申诉。
    </p>
    <p>
      （6）看看云医有权基于您的偏好特征向您推送其可能感兴趣的商业短信息，如果您不想接受看看云医发送的商业广告信息，可以随时与客服联系，看看云医客服将在及时合理期限内回复您请求。同时，看看云医应确保推送的商业短信息符合相关法律法规要求，不得过度推送或干扰用户正常使用。
    </p>
    <p></p>
    <p>2.看看云医的义务</p>
    <p>
      2.2.1
      看看云医通过国际互联网（Internet）为您提供网络服务，并承担本协议和服务条款规定的对您的责任和义务。看看云医应确保服务的稳定性和安全性，如因看看云医的原因导致服务中断或故障，应及时采取措施恢复服务，并向用户说明情况。
    </p>
    <p>
      2.2.2
      看看云医有义务对您在注册使用看看云医中所遇到的与注册或服务有关的问题及反映的情况作出相应的回复。回复应在合理期内完成，且回复内容应准确、清晰。
    </p>
    <p>
      2.2.3
      看看云医发给您的通告一般应通过重要页面的公告或电子邮件传送。同时，看看云医应确保通告内容的准确性和及时性，如通告涉及重大事项，应采取多种方式通知用户，确保用户知晓。
    </p>
    <p></p>
    <p>（三）服务</p>
    <p></p>
    <p>1.服务内容</p>
    <p>
      3.1.1
      您可通过看看云医接受多种服务，具体的服务内容、服务方式、服务对象及其他的要求以看看云医针对具体的服务发布的章程、服务条款和操作规则具体执行。看看云医应在提供服务前，向用户明确说明服务的具体内容、收费标准（如有）、服务期限等关键信息。
    </p>
    <p></p>
    <p>2.服务责任</p>
    <p>3.2.1 您明确理解和同意看看云医对如下事宜不承担任何法律责任的声明：</p>
    <p>
      3.2.2
      对您使用看看云医、与看看云医相关的任何内容、服务或其它链接至看看云医的站点、内容均不作直接、间接、法定、约定的保证；同时，看看云医应在用户使用相关内容、服务或链接站点时，进行风险提示，告知用户可能存在的风险。
    </p>
    <p>
      3.2.3
      无论在任何原因下(包括但不限于疏忽原因)，对您或任何人通过使用看看云医上的信息或由看看云医链接的信息，或其他与看看云医链接的平台信息所导致的损失或损害(包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果)，责任均由使用者自行承担(包括但不限于疏忽责任)；在提供相关信息或链接时，看看云医应尽到合理的审查义务，确保信息和链接的合法性和安全性。
    </p>
    <p>
      3.2.4
      使用者对看看云医的使用即表明同意承担浏览看看云医的全部风险，“看看云医”对使用者在看看云医存取资料所导致的任何直接或间接的金钱上的损失不承担任何责任；看看云医应采取合理的安全措施，保护用户存取资料的安全，如因看看云医的安全漏洞导致用户损失，看看云医应承担相应责任。
    </p>
    <p>
      3.2.5
      因不可抗力导致的服务中断或终止，本平台不承担责任，不可抗力包括但不限于自然灾害、政府行为、网络故障等；同时，看看云医应在不可抗力事件发生后，及时通知用户，并采取合理措施减少用户损失。
    </p>
    <p>
      3.2.6
      您违反有关法律或者本协议之规定，使看看云医遭受任何损失，受到任何第三方的索赔，或任何行政管理部门的处罚，您应对看看云医提供补偿，包括但不限于合理的律师费、诉讼费、保全费、鉴定评估费、执行费等相关的实际支出费用。看看云医应在遭受损失后，及时通知用户，并提供相关证据，以便用户进行补偿。
    </p>
    <p>
      3.2.7
      互助服务说明：本服务为公益互助性质，严厉打击任何形式的商业买卖行为。您可以根据自身情况，发布相关求助信息（包括：求助说明）、援助信息（帮助说明），看看云医仅仅作为技术提供方，不对您回复的真实性准确性等做任何保证、不提供具体药物、医疗器械等物品交换流程。如您发现商业买卖或索要钱财等行为，可向看看云医客服进行举报。请您对所展示的信息仔细辨别真伪，请获取物品时仔细检查物品，若有任何破损、质量问题或者其他问题，请停止使用；若您提供物品，请保证物品不违反任何法律规定，请保证提供物品满足对方需求且在有效期内。处方药需根据医生.处方服用。请勿擅自使用处方药。服用药品时请按照说明书或医嘱使用。您知悉因使用本服务所提供应用、数据、小程序等而造成的任何损失，由您承担。如果由于互助过程中导致的财产或生命安全损失，看看云医不承担任何责任。同时，看看云医应在互助服务页面进行明显的风险提示，告知用户互助服务的性质和可能存在的风险。
    </p>
    <p>
      3.2.8
      尽管看看云医尽力确保本平台所有信息的正确性和适时性，但并不能保证其为完全准确和最新，看看云医对本平台信息的准确性、完整性和真实性不作任何承诺；对信息的迟延、失误和遗漏不承担任何责任。本平台及其中的所有信息和资料根据医院等机构官方平台或健康机构、从业人员提供信息提供给您，不包含任何其他表述和暗示。看看云医应在信息展示页面进行明显的免责声明，告知用户信息的不确定性和风险。
    </p>
    <p></p>
    <p>3.服务的中断与终止</p>
    <p>3.3.1 用户申请</p>
    <p>
      您有权向看看云医提出注销看看云医账号，但须经看看云医审核同意后由看看云医注销该账号，至此您才解除与看看云医的服务协议关系。但注销该账号后，看看云医仍保留下列权利：
    </p>
    <p>
      （1）您的账号注销后，看看云医应删除该您的注册数据及以前的接受服务的记录或对相应记录做匿名化处理；同时，看看云医应在注销账号前，向用户明确说明注销后的处理方式和可能的影响。
    </p>
    <p>
      （2）您的账号注销后，如您在注销前在看看云医上存在违法行为或违反合同的行为，看看云医仍可行使本服务协议所规定的权利。
    </p>
    <p>3.3.2 若您违法，违反本约定：</p>
    <p>
      看看云医可依法或本约定终止您的账号或对服务的使用，并删除您在使用看看云医过程中提交的任何资料。该任何理由包括但不仅限于看看云医认为您已违反本协议的字面意义和精神、以不符合本协议的字面意义和精神的方式行事等。
    </p>
    <p>
      看看云医可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供“服务”或其任何部份。账号终止后，看看云医没有义务为您保留原账号中或与之相关的任何信息。
    </p>
    <p>在下列情况下，看看云医可以通过注销账号的方式终止服务：</p>
    <p>
      （1）在您违反本服务协议相关规定时，看看云医有权终止向该您提供服务。看看云医将在中断服务时通知您。但如该您在被看看云医终止提供服务后，再一次直接或间接或以他人名义注册为看看云医用户的，看看云医有权再次单方面终止向该您提供服务；
    </p>
    <p>
      （3）一旦看看云医发现您注册数据中主要内容是虚假的，看看云医有权随时终止向该您提供服务；
    </p>
    <p>（4）本服务协议终止或更新时，您明示不愿接受新的服务协议的；</p>
    <p>
      （5）其它看看云医认为需终止服务的情况。在终止服务前，看看云医应进行合理的通知和解释，确保用户知晓终止服务的原因和后果。
    </p>
    <p></p>
    <p>（四）信息使用和隐私权保护</p>
    <p></p>
    <p>
      1.个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
    </p>
    <p>
      2.为实现您选择使用的功能、服务，或为遵守相关法律法规的要求，您需向我们提供必要的个人信息。看看云医将按照《看看云医法律声明及隐私政策》处理和保护您的个人信息。同时，看看云医应在用户注册或使用服务时，明确告知用户个人信息的收集、使用、存储和保护方式，以及用户的权利和义务。
    </p>
    <p>
      <!-- ：https://policy.ynkkyy.com/#/platformPrivacy -->
      3.关于个人信息的更多处理规则，请访问我们的隐私政策以了解更详细的情况。
      <!-- <router-link to="/platformPrivacy">（隐私条款）</router-link> -->
      <el-link @click="clickPath" type="primary">（隐私条款）</el-link>
    </p>
    <p>（五）保证和责任</p>
    <p></p>
    <p>1.您知悉以下的约定并表示予以无条件的接受与承诺：</p>
    <p>
      5.1
      您因进行交易、获取有偿服务或接触看看云医服务而发生的所有应纳税赋，以及一切硬件、软件、服务及其它方面的费用均由您负责支付。同时，您应确保支付的合法性和及时性，如因支付问题导致的服务中断或其他后果，由您自行承担。
    </p>
    <p>
      5.2
      您对网络服务的使用承担风险。看看云医对此不作任何类型的担保，不论是明确的或隐含的。看看云医不担保服务一定能满足您的要求，也不担保服务不会受中断，对服务的及时性、安全性、出错发生都不作担保。看看云医对在看看云医上得到的任何商品购物服务或交易进程(若有)，不作担保。您不会从“看看云医”得到口头或书写的意见或信息，也不会在这里做明确担保。看看云医应在服务页面进行明显的风险提示，告知用户服务的不确定性和风险。
    </p>
    <p>
      5.3
      对您信息的存储和限制。“看看云医”不对非因“看看云医”原因导致的您所发布信息的删除或储存失败负责。“看看云医”有判定您的行为是否符合看看云医服务条款的要求和精神的保留权利，如果您违背了服务条款的规定，则中断您的服务及帐号。同时，看看云医应采取合理的技术措施，确保用户信息的安全存储，如因看看云医的技术问题导致信息丢失或损坏，看看云医应承担相应责任。
    </p>
    <p>5.4 网络服务的内容所有权。</p>
    <p>
      看看云医定义的内容包括：文字、软件、声音、相片、录像、图表；广告中的全部内容；电子邮件中的全部内容；看看云医为您提供的其他信息。所有这些信息均受版权、商标、标签和其他财产所有权法律的保护。您只能在看看云医和广告商的授权下才能使用这些内容，不得擅自复制、再造或创造与这些内容有关的派生产品。看看云医的所有内容版权归原文作者和看看云医共同所有，任何人需要转载看看云医的内容，必须获得原文作者或看看云医的授权。看看云医应在内容展示页面进行明显的版权声明，告知用户内容的所有权和使用限制。
    </p>
    <p>
      5.5
      您承诺自己在使用看看云医时实施的所有行为均遵守中华人民共和国法律、法规和看看云医的相关规定以及各种社会公共利益或公共道德，如有违反而导致任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任。同时，看看云医有权对您的违规行为进行记录，并在必要时向相关部门报告。
    </p>
    <p>
      5.6
      您完全知悉并同意，“看看云医”及看看云医为合作医院提供医院市场推广服务、医疗咨询服务、随访资讯服务、短信提醒服务和预约资讯服务等方面的技术和运营服务，均系在遵守《互联网信息服务管理办法》等国家有关卫生管理法律法规规定的前提下开展的经营活动，您完全知悉并理解国家对医疗行业的法律限制，亦完全知悉如下的内容，并无任何不清晰之处：
    </p>
    <p>
      看看云医和合作医院所提供的医疗咨询类服务系国家法律法规规定的医疗卫生信息服务，并不属于网上诊疗活动，您不得据此进行任何的治疗活动；看看云医为合作医院提供的医院市场推广服务和预约资讯服务，系公开的医疗卫生信息服务，看看云医并不具体撮合您前往合作医院处就诊，您享有完全自主的判断和选择的权利，不得将看看云医提供的该等医疗卫生信息服务错误的理解为一种“医托行为”；看看云医与其他第三方合作所开展的其他活动，包括但不限于药品信息服务，看看云医仅作为技术服务平台，并不作为具体交易的主体，亦不作为网上交易的第三方平台，您知悉该等规定，并无条件同意不违反看看云医的该等平台政策和国家法律法规的强制性规定。同时，看看云医应在相关服务页面明确告知用户服务的性质和限制，避免用户产生误解。
    </p>
    <p>
      5.7 您知悉并同意：若百度、谷歌等5.7
      您知悉并同意：若百度、谷歌等搜索引擎自带缓存，您搜索到看看云医已经删除或处理的内容时，您将自行联系搜索引擎服务商进行处理。此类问题看看云医无权也无法处理。此外，看看云医可在网站显著位置提醒用户可能存在的搜索引擎缓存问题及处理方式，以避免用户产生误解和不必要的困扰。
    </p>
    <p></p>
    <p>
      5.8
      第三方服务：为丰富您的体验，看看云医可能会提供第三方制作和提供的外部链接。然而，除非另有声明，看看云医无法控制第三方制作和提供的内容、服务质量及安全性。您因使用或依赖第三方制作和提供的内容、服务所产生的损失或损害，看看云医不承担任何责任。在提供第三方服务链接时，看看云医应进行必要的风险提示，明确告知用户第三方服务的不确定性和潜在风险，以便用户在使用时能够谨慎决策。
    </p>
    <p></p>
    <p>
      5.9
      看看云医所提供的任何各项电子信息、医疗咨询等服务仅供参考，不能替代医生和其他医务人员的专业建议。您在使用看看云医中资料时应保持理性和谨慎，如自行使用看看云医中资料发生偏差，看看云医不承担任何法律责任。同时，看看云医应在提供相关服务时明确提醒用户服务的参考性质，避免用户过度依赖，确保用户能够正确认识和使用这些服务。
    </p>
    <p></p>
    <p>（六）争议解决方法</p>
    <p></p>
    <p>
      1.凡因解释或执行服务协议所发生的或与本服务协议有关的一切争议，双方应本着友好协商的原则解决。在协商过程中，双方应保持理性和克制，积极寻求妥善的解决方案。如协商不成，任何一方可以申请昆明市的机构调解或向昆明仲裁委员会仲裁。
    </p>
    <p></p>
    <p>
      2.本协议适用中华人民共和国法律。在协议的执行过程中，双方应严格遵守中国法律的规定，确保协议的合法性和有效性。
    </p>
    <p></p>
    <p>（七）附则</p>
    <p></p>
    <p>
      1.您点击“同意”按钮即视为您签署本协议。本协议自您签署之时起生效，有效期至您申请注销或看看云医主动注销账号为止。在用户点击“同意”按钮前，看看云医应进行明显的提示，清晰地告知用户协议的重要性和法律后果，确保用户在充分了解的基础上做出决策。
    </p>
    <p></p>
    <p>
      2.本协议的最终解释权归看看云医所有，本协议未尽事宜，按照相关法律法规执行。同时，看看云医在行使最终解释权时，应遵循公平、合理、合法的原则，不得滥用解释权损害用户权益。看看云医应始终以用户为中心，不断完善和优化服务协议，为用户提供更加优质、安全、可靠的服务。
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  },

  methods: {
    clickPath() {
      this.$router.push({
        path: "/platformPrivacy",
        // query: {
        //   //query是个配置项
        //   age: 20,
        // },
      });
    },
  },
};
</script>
